"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventSourceType = void 0;
/**
 * Used for Eventbridge Event Bus Event Rules and their
 * invocations using the eventPattern.source property
 *
 * NOTE: These should be appended with the Environment Name
 * to avoid conflicts with other environments:
 *
 * Examples:
 * ${EventSourceType.PATIENT_API}-dev for patient-api-dev
 * ${EventSourceType.PATIENT_API}-jdoe for John Doe's Ephemeral Deployment
 */
var EventSourceType;
(function (EventSourceType) {
    EventSourceType["PATIENT_API"] = "patient-api";
})(EventSourceType = exports.EventSourceType || (exports.EventSourceType = {}));
